import styled, { css } from 'styled-components';
import { AntInputReset } from 'styles/resets';
export var Container = styled.div.withConfig({
  displayName: "styles__Container",
  componentId: "sc-lm09rd-0"
})(["position:relative;display:flex;align-items:center;height:48px;background-color:#ffffff;border:2px solid #ffffff;border-radius:5px;box-shadow:0 10px 20px 0 rgba(0,0,0,0.2);padding:0 16px;margin-bottom:16px;transition:all 0.4s;input{display:flex;justify-content:flex-start;align-items:center;height:100%;width:100%;padding:0;color:#11151a;&::placeholder{color:#9b9fa4;font-weight:normal;font-size:15px;}}", ";", ";", ";"], function (containerProps) {
  return containerProps.hasFocus && css(["border-color:", ";"], function (props) {
    return props.theme.colors.green.regular;
  });
}, function (props) {
  return props.disabled && css(["background-color:#f3f4f8;border-color:#f3f4f8;cursor:not-allowed;input[disabled]{color:#6e7191;cursor:not-allowed;}"]);
}, AntInputReset);