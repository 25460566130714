import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.values.js";
import { captureException } from '@sentry/nextjs';
import { showMessage } from '../lib/messages';

var handle500 = function handle500(error) {
  captureException(error);
  showMessage({
    title: 'Unexpected Error',
    message: 'An unexpected error ocurred. Please try again later.',
    type: 'error'
  });
};

var handle303 = function handle303() {
  return null;
};

var handle400 = function handle400(error) {
  var _error$response;

  captureException(error);

  var _error$response$data = error === null || error === void 0 ? void 0 : (_error$response = error.response) === null || _error$response === void 0 ? void 0 : _error$response.data,
      message = _error$response$data.message;

  showMessage({
    title: 'Error',
    message: message,
    type: 'error'
  });
};

var handle401 = function handle401(error) {
  var _error$response2;

  captureException(error);

  var _error$response$data2 = error === null || error === void 0 ? void 0 : (_error$response2 = error.response) === null || _error$response2 === void 0 ? void 0 : _error$response2.data,
      code = _error$response$data2.code,
      message = _error$response$data2.message;

  switch (code) {
    case 'organizations.pending':
      showMessage({
        title: 'Pending Approval',
        message: message,
        type: 'error'
      });
      break;

    case 'invalid.credential':
      showMessage({
        title: 'Invalid credentials.',
        message: 'Please verify your email and password.',
        type: 'error'
      });
      break;

    default:
      showMessage({
        title: 'Error',
        message: message,
        type: 'error'
      });
      break;
  }
};

var handle406 = function handle406(error) {
  var _error$response3;

  captureException(error);

  var _error$response$data3 = error === null || error === void 0 ? void 0 : (_error$response3 = error.response) === null || _error$response3 === void 0 ? void 0 : _error$response3.data,
      message = _error$response$data3.message;

  showMessage({
    title: 'Forbidden',
    message: message,
    type: 'error'
  });
};

var handle409 = function handle409(error) {
  var _error$response4;

  captureException(error);

  var _error$response$data4 = error === null || error === void 0 ? void 0 : (_error$response4 = error.response) === null || _error$response4 === void 0 ? void 0 : _error$response4.data,
      message = _error$response$data4.message;

  showMessage({
    title: 'Oops!',
    message: message,
    type: 'error'
  });
};

var handle422 = function handle422(error, setError) {
  var _error$response5;

  var _error$response$data5 = error === null || error === void 0 ? void 0 : (_error$response5 = error.response) === null || _error$response5 === void 0 ? void 0 : _error$response5.data,
      errors = _error$response$data5.errors;

  console.log(errors);
  errors === null || errors === void 0 ? void 0 : errors.forEach(function (err) {
    if (setError) {
      setError(Object.keys(err)[0], {
        type: 'manual',
        message: String(Object.values(err)[0])
      });
    } else {
      showMessage({
        message: String(Object.values(err)[0]),
        type: 'error'
      });
    }
  });
  captureException(error);
};

var handlerFunction = function handlerFunction(error, callback, params) {
  var _ref = error || {},
      response = _ref.response;

  if (!response) {
    handle500(error);
  }

  var status = response === null || response === void 0 ? void 0 : response.status;
  var handleResponse = null;

  switch (status) {
    case 303:
      handleResponse = function handleResponse() {
        return handle303();
      };

      break;

    case 400:
      handleResponse = function handleResponse() {
        return handle400(error);
      };

      break;

    case 401:
      handleResponse = function handleResponse() {
        return handle401(error);
      };

      break;

    case 406:
      handleResponse = function handleResponse() {
        return handle406(error);
      };

      break;

    case 409:
      handleResponse = function handleResponse() {
        return handle409(error);
      };

      break;

    case 422:
      handleResponse = function handleResponse() {
        return handle422(error, params === null || params === void 0 ? void 0 : params.setError);
      };

      break;

    default:
      handleResponse = function handleResponse() {
        return handle500(error);
      };

      break;
  }

  if (callback) callback();
  return handleResponse();
};

export var handler = handlerFunction;