import styled, { css } from 'styled-components';
import { AntInputReset } from 'styles/resets';
export var Container = styled.div.withConfig({
  displayName: "styles__Container",
  componentId: "sc-10wzm0c-0"
})(["position:relative;display:flex;flex-direction:column;justify-content:center;height:max-content;min-height:48px;background-color:#ffffff;border:2px solid #ffffff;border-radius:5px;box-shadow:0 10px 20px 0 rgba(0,0,0,0.2);padding:16px;transition:all 0.4s;.ant-input-textarea{height:max-content;min-width:100%;}.counter{position:absolute;display:flex;bottom:-32px;right:16px;font-weight:normal;font-size:15px;color:#4d5156;}", " textarea{display:flex;justify-content:flex-start;align-items:center;height:max-content;width:100%;padding:0;color:#11151a;&::placeholder{color:#9b9fa4;font-weight:normal;font-size:15px;}}", ";", ";", ";"], function (props) {
  return props.showCount && css(["margin-bottom:24px;"]);
}, function (containerProps) {
  return containerProps.hasFocus && css(["border-color:", ";"], function (props) {
    return props.theme.colors.green.regular;
  });
}, function (props) {
  return props.disabled && css(["background-color:#f3f4f8;border-color:#f3f4f8;cursor:not-allowed;textarea[disabled]{color:#6e7191;cursor:not-allowed;}"]);
}, AntInputReset);