import { useState, useEffect } from 'react';
export function usePersistedState(key, defaultValue) {
  var _useState = useState(function () {
    return JSON.parse(true && localStorage.getItem(key)) || defaultValue;
  }),
      state = _useState[0],
      setState = _useState[1];

  useEffect(function () {
    localStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);
  return [state, setState];
}
export function usePersistedSessionState(key, defaultValue) {
  var _useState2 = useState(function () {
    return JSON.parse(sessionStorage.getItem(key)) || defaultValue;
  }),
      state = _useState2[0],
      setState = _useState2[1];

  useEffect(function () {
    sessionStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);
  return [state, setState];
}