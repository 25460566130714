import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.values.js";
export var haveAlert = function haveAlert(country) {
  var alerts = [{
    XK: 'References to Kosovo shall be understood to be in the context of Security Council resolution 1244 (1999)'
  }];
  var alert;
  alerts.forEach(function (item, index) {
    if (Object.keys(item)[0] === country) {
      alert = Object.values(alerts[index]);
    }
  });
  return alert;
};